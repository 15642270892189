import React, { useState, useEffect } from 'react';
import './Orders.css';
import { db } from './firebase';
import { useStateValue } from './StateProvider';
import Order from './Order';

function Orders() {
	const [{ basket, user }, dispatch] = useStateValue();
	const [orders, setOrders] = useState();

	useEffect(() => {
		if (user) {
			db.collection('users')
				.doc(user?.uid)
				.collection('orders')
				.orderBy('created', 'desc')
				.onSnapshot(snapshot =>
					setOrders(
						snapshot.docs.map(doc => ({
							id: doc.id,
							data: doc.data()
						}))
					)
				);
		} else {
			setOrders([]);
		}
	}, []);

	return (
		<div className='orders'>
			<h1>Your Orders</h1>
			<div className='orders__order'>
				{orders?.map(orders => (
					<Order order={orders} />
				))}
			</div>
		</div>
	);
}

export default Orders;
